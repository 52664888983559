import React, { useEffect } from 'react'
import { FieldToggleStatus } from '@features/nbee/EmailReceipt/FieldToggleStatus'
import { FieldRecipients } from '@features/nbee/EmailReceipt/FieldRecipients'
import { FieldSubject } from '@features/nbee/EmailReceipt/FieldSubject'
import { Form, useFormikContext } from 'formik'
import { EmailReceiptStyled } from '@features/nbee/EmailReceipt/styled'
import { EmailReceiptSettings } from 'Nbee'

const { SettingsFieldsContainer, Wrapper } = EmailReceiptStyled

interface Props {
  handleSubmit: (values: EmailReceiptSettings) => void
  isBridgeEnabled?: boolean
}

export const FormInner: React.VFC<Props> = ({
  handleSubmit,
  isBridgeEnabled,
}) => {
  const { values, setValues, touched, setTouched, initialValues } =
    useFormikContext<EmailReceiptSettings>()

  // every time we open the setting we reset the touched state
  useEffect(() => {
    if (values.active) {
      setTouched({
        ...touched,
        recipients: false,
        subject: false,
      })
    }
  }, [values.active])

  useEffect(() => {
    handleSubmit(values)
  }, [values])

  return (
    <Form>
      <Wrapper>
        <FieldToggleStatus
          forcedDisabled={isBridgeEnabled}
          name={'active'}
          onStatusChange={(isActive) => {
            // when user "activate" or "deactivate" the toggle we set default values
            // (initial values in case of "on" and empty values in case of "off")
            // and submit the form to store the preference
            const newValues = isActive
              ? {
                  ...initialValues,
                  active: isActive,
                }
              : {
                  active: false,
                  recipients: [],
                  subject: '',
                }

            setValues(newValues, false)
            handleSubmit(newValues)
          }}
        />

        {values.active ? (
          <SettingsFieldsContainer>
            <FieldSubject disabled={isBridgeEnabled} name={'subject'} />
            <FieldRecipients disabled={isBridgeEnabled} name={'recipients'} />
          </SettingsFieldsContainer>
        ) : null}
      </Wrapper>
    </Form>
  )
}
