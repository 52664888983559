import React, { useContext, useEffect, useMemo } from 'react'
import { FieldMappingRow } from '@features/nbee/FieldsMappingForm/FieldMappingRow'
import { SelectValue } from '@components/Form/InputSmartSelect'
import Fuse from 'fuse.js'
import { useField, useFormikContext } from 'formik'
import { MappedField, BridgeFormValues } from 'Nbee'
import { ApiBridgeFieldsList, ApiUserModuleItem } from 'BackendApi'
import { PanelPopupContext } from '@components/Panel'
import { FieldMappingEmptyState } from '@features/nbee/FieldsMappingForm/FieldMappingEmptyState'
import { getSortedFields, sortByFieldLabel } from './sortingUtils'
import { PopupContentNoSelectOptions } from '@features/nbee/FieldsMappingForm/popupContent/PopupContentNoSelectOptions'
import { isFieldMapped } from '@features/nbee/FieldsMappingForm/utils'
import {
  closeAlertMessage,
  sendAlertMessage,
} from '@app/store/actions/ApplicationConfigurationActions'
import { useAppDispatch } from '@app/store/hooks'
import { useTranslation } from 'react-i18next'
import { useGetFormulasSchema } from '@app/api/getFormulasSchema'
import { Button } from '@components/Basic/ButtonNbe'
import { useUpdateBridgeToPricing } from '@features/nbee/utils'

interface Props {
  bridgeId: number
  filterText: string
  showAllFields: boolean
  onResetFilterRequest: () => void
  allBridgeFields?: ApiBridgeFieldsList
  formulaUserModule?: ApiUserModuleItem | undefined
  totalFieldsMapped: number
  maxFields?: number
}

export const FormInner: React.FC<Props> = ({
  filterText,
  showAllFields,
  onResetFilterRequest,
  allBridgeFields,
  bridgeId,
  formulaUserModule,
  totalFieldsMapped,
  maxFields,
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const panelContext = useContext(PanelPopupContext)
  const [field, meta, helpers] = useField<MappedField[]>('fieldsMapping')

  const { data: formulasSchemaData } = useGetFormulasSchema(`${bridgeId}`)

  const updateBridgeToPricing = useUpdateBridgeToPricing(bridgeId)

  const foundSomeAutoMapped = field.value.some(
    (f) => isFieldMapped(f) && f.mappingType === 'auto'
  )

  const { isValid, setFieldValue } = useFormikContext<BridgeFormValues>()

  const unmapAllFields = () => {
    const updatedArray = field.value.map((item) => {
      return { ...item, sourceFieldId: undefined }
    })
    helpers.setValue(updatedArray)
  }

  useEffect(() => {
    if (maxFields !== undefined && totalFieldsMapped > maxFields) {
      dispatch(
        sendAlertMessage({
          isDismissable: false,
          message: t('nbee.fieldsMapping.fieldMappingAlertTitle'),
          useTranslation: true,
          transValue: maxFields,
          buttons: (
            <div style={{ display: 'flex', gap: '3rem' }}>
              <Button
                onClick={() => {
                  updateBridgeToPricing()
                }}
                $variant={'primary'}
                $size={'small'}
              >
                {t('nbee.checkCompatibility.updatePlanCta')}
              </Button>
              <Button
                onClick={() => unmapAllFields()}
                $variant={'action'}
                $size={'small'}
              >
                Unmap All Fields
              </Button>
            </div>
          ),
        })
      )
    } else if (maxFields !== undefined && totalFieldsMapped <= maxFields) {
      dispatch(closeAlertMessage())
    }
  }, [totalFieldsMapped])

  // this is to scroll to the first error found on the page
  // this will be possibile since InputFeedback component has a `data-error` attribute
  /* useEffect(() => {
    const fieldWithError = document.querySelector("[data-error='true']")
    if (fieldWithError) {
      fieldWithError.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      })
    }
  }, [isValid]) */

  const formFields = field.value
  const sourceFields =
    allBridgeFields?.source.sort((a, b) => a.label.localeCompare(b.label)) || []

  const selectOptions: SelectValue[] = useMemo(() => {
    const sourceFieldOptions = sourceFields.map((field) => ({
      value: field.id,
      label: field.label,
      type: 'sourceField',
    }))

    const formulaOptions: SelectValue[] =
      formulasSchemaData?.data
        // .filter((formula) => !formula.private)
        // no need to filter out private functions,
        // as the BE api will give us an already filtered schema
        // including only the formulas that the user is allowed to see
        .map((formula) => {
          // Get source fields used in first param of type 'field'
          const sourceFieldUsedInFirstFormulaParamValue = [...formFields]
            .filter((f) => f.destinationFieldType === 'formula')
            .find((f) => f.formula?.id === formula.id)
            ?.formula?.params.filter(
              (param) => param && param?.type === 'field'
            )[0]?.values
          const sourceFieldUsedInFirstFormulaParamLabel =
            sourceFieldOptions.find(
              (o) => o.value === sourceFieldUsedInFirstFormulaParamValue
            )?.label || []
          const formulaLabel =
            (sourceFieldUsedInFirstFormulaParamValue &&
              sourceFieldUsedInFirstFormulaParamLabel &&
              `${t(
                `nbee.formulas.${formula.id}.Name` as any
              )} - ${sourceFieldUsedInFirstFormulaParamLabel}`) ||
            `${t(`nbee.formulas.${formula.id}.Name` as any)}`

          return {
            value: formula.id,
            label: formulaLabel,
            secondaryLabel: t(`nbee.formulas.${formula.id}.Description` as any),
            dropdownPrimaryLabel: `${formula.category} >> ${t(
              `nbee.formulas.${formula.id}.Name` as any
            )}`,
            type: 'formula',
          }
        })
        .sort((a, b) =>
          a.dropdownPrimaryLabel.localeCompare(b.dropdownPrimaryLabel)
        ) || []

    const mappedFormulaOptions = [...formFields]
      .map((f, i) => ({
        ...f.formula,
        index: i,
      }))
      .filter((f) => f.id)
      .map((f) => ({
        value: `${f?.id}`,
        label: `${t(
          `nbee.formulas.${f?.id}.Name` as any
        )} - ${sourceFieldOptions.find(
          (o) => f?.params && f?.params[0] && o.value === f?.params[0].values
        )?.label}`,
        type: 'mappedFormula',
        index: f?.index,
      }))
    return [...sourceFieldOptions, ...formulaOptions, ...mappedFormulaOptions]
  }, [sourceFields, formulasSchemaData, t, meta.touched])

  useEffect(() => {
    if (meta.touched) {
      helpers.setTouched(false) // reset touched state
    }
  }, [selectOptions])

  // Since we also need to perform search against selected value, we can compute it
  // and prepare a computedMappedString which contains the "label" of the selected source field
  // or the text manually added
  const formFieldsWithComputedMappedValues = formFields
    .map((field) => ({
      ...field,
      computedMappedString: field.sourceFieldId
        ? sourceFields.find((f) => f.id === field.sourceFieldId)?.label || ''
        : field.destinationText,
    }))
    .sort(sortByFieldLabel)

  const fuzzySearch = new Fuse(formFieldsWithComputedMappedValues, {
    isCaseSensitive: false,
    shouldSort: true,
    findAllMatches: true,
    includeMatches: true,
    includeScore: true,
    threshold: 0.25, // 0 for exact match of letter and location, 1 to match everything
    distance: 500,
    keys: ['destinationFieldLabel', 'computedMappedString'],
  })

  const filteredDestinationFieldsByText = filterText
    ? fuzzySearch.search(filterText).map(({ item }) => item)
    : formFields

  const filteredDestinationFieldsByState = showAllFields
    ? filteredDestinationFieldsByText
    : filteredDestinationFieldsByText.filter(
        ({ destinationText, sourceFieldId, formula }) =>
          destinationText || sourceFieldId || formula
      )

  // we memoize the sorting so we won't loose order while mapping by moving fields up and down
  const fieldsToRender = useMemo(
    () => getSortedFields(filteredDestinationFieldsByState),
    [filterText, showAllFields, formFields.length]
  )

  const noSelectOptions = selectOptions.length < 1

  useEffect(() => {
    if (noSelectOptions) {
      if (panelContext && panelContext.sendPopup) {
        panelContext.sendPopup({
          content: <PopupContentNoSelectOptions />,
          dismissable: true,
        })
      }
    }
  }, [noSelectOptions])

  useEffect(() => {
    // DONE include check for removed source fields used in formulas
    // every time the select options change we need to check if some field was using it

    // Get source fields used in formulas
    const sourceFieldsUsedInFormulas = formFields
      .filter((f) => f.destinationFieldType === 'formula')
      .flatMap((f) => f?.formula?.params)
      .filter((param) => param?.type === 'field')
      .map((fieldParam) => fieldParam?.values)

    const allSourceFieldUsed = [
      ...formFields
        .filter((f) => f.destinationFieldType === 'source') // we only filter in values set as source
        .map((f) => f.sourceFieldId)
        .filter((sourceFieldId) => !!sourceFieldId),
      ...sourceFieldsUsedInFormulas.filter((item) => !!item), // Include source fields used in formulas
    ] as string[]

    const allAvailableOptions = selectOptions.map((o) => o.value)
    // we create an array of used source fields that now are not available anymore inside our options
    const usedSourceFieldsNotAvailableWithDuplicates =
      allSourceFieldUsed.filter((item) => !allAvailableOptions.includes(item))
    // removing dups
    const usedSourceFieldsNotAvailable = Array.from(
      new Set(usedSourceFieldsNotAvailableWithDuplicates)
    )

    const isValueInAvailableOptions = (
      value: string | string[] | null | undefined
    ) => {
      if (typeof value === 'string') {
        return allAvailableOptions.includes(value)
      } else if (Array.isArray(value)) {
        return value.some((v) => allAvailableOptions.includes(v))
      } else {
        return false
      }
    }

    // we clenaup formik state
    const cleanedFormFieldValues = formFields.map((field) => {
      if (
        field.destinationFieldType === 'source' &&
        field.sourceFieldId &&
        usedSourceFieldsNotAvailable.includes(field.sourceFieldId)
      ) {
        return {
          ...field,
          sourceFieldId: '',
        }
      } else if (field.destinationFieldType === 'formula') {
        return {
          ...field,
          formula: {
            ...field.formula,
            id: field?.formula?.id || '',
            params:
              field?.formula?.params.map((param) => {
                if (
                  param.type === 'field' &&
                  !isValueInAvailableOptions(param.values)
                ) {
                  return {
                    ...param,
                    values: '', // Cleanup the param value for not found fields
                  }
                } else {
                  return param
                }
              }) || [],
          },
        }
      } else {
        return field
      }
    })
    helpers.setValue(cleanedFormFieldValues)

    // we notify user
    if (usedSourceFieldsNotAvailable.length) {
      dispatch(
        sendAlertMessage({
          isDismissable: true,
          message: t('nbee.fieldsMapping.missingMappedFields', {
            listOfNotFoundIds: usedSourceFieldsNotAvailable.join(', '),
          }),
        })
      )
    }
  }, [selectOptions])

  return allBridgeFields ? (
    <div style={{ paddingBottom: '2rem' }}>
      {fieldsToRender.length ? (
        fieldsToRender.map((field) => {
          // we pass the index of formFields and not the one of the filtered array
          // or the entire formik logic inside the component (get value, error and touched state)
          // will not work because will refer to different index
          const index = formFields.findIndex(
            ({ destinationFieldId }) =>
              field.destinationFieldId === destinationFieldId
          )

          if (index < 0) {
            // prevent passing negative index in case a field does not exists anymore (after field refresh)
            return null
          }

          return (
            <FieldMappingRow
              index={index}
              key={field.destinationFieldId}
              selectOptions={selectOptions}
              formulaUserModule={formulaUserModule}
              // onNotAvailableFeatureRequest={onNotAvailableFeatureRequest}
            />
          )
        })
      ) : (
        <FieldMappingEmptyState
          onResetFilterRequest={onResetFilterRequest}
          isFiltered={!!formFields.length}
        />
      )}
    </div>
  ) : null
}
